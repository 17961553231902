import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { AiOutlineLoading } from "react-icons/ai";
import Logo from "../assets/logo/Logo.png";
import useQuery from "../hooks/useQuery";

import api from "../api";

const getCookie = (name) => Cookies.get(name);

const Provider = () => {
  const [token, setToken] = useState();
  const query = useQuery();
  const id = query.get("id");
  const history = useHistory();

  const getToken = () => {
    const cookieToken = getCookie("Authorization");
    if (cookieToken) {
      setToken(cookieToken);
    } else {
      //This url differs between beta and prod
      window.location.href = "https://dashboard.coherentrx.com/Home/Login";
      return;
    }
  };

  const checkAppointmentStatus = useCallback(async () => {
    const data = {
      token,
      id,
    };
    console.log(data, "data");
    const { status, redirectURL } = await api.getAppointmentStatus({ ...data });

    if (status === "deploying telehealth room") {
      return checkAppointmentStatus();
    }
    if (status === "all validated") {
      window.location.href = redirectURL;
      return;
    }
    if (status === "appointment out of time") {
      history.push("/reject");
      return;
    }
    if (status === "token expired") {
      window.location.href = redirectURL;
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, id]);

  useEffect(() => {
    getToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token && id) {
      checkAppointmentStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, id]);

  return (
    <div className="flex justify-center items-center min-h-screen max-w-screen-sm mx-auto px-2 sm:px-4">
      <div className="p-4 sm:p-8 flex flex-col items-center justify-center sm:shadow-xl text-center">
        <div className="max-w-xs">
          <img src={Logo} alt="DETROIT logo" />
        </div>
        <h2 className="text-center text-xl mb-4">
          Preparing the meeting room for your video visit.
        </h2>
        <AiOutlineLoading className="animate-spin text-teal-peg text-6xl" />
        <p className="text-lg sm:mx-6 my-4">
          To ensure maximum security, participants can only enter the meeting
          rooms 5 minutes before their video visit appointment time.
        </p>
      </div>
    </div>
  );
};

export default Provider;
